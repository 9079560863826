import { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
// import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';

import Routers from 'routers';
import Toast from 'components/toast';
// import { getFirebaseToken, onMessageListener } from './firebase';
import './index.css';
// import { useSubscribeToTopicMutation } from 'app/services/messaging';
import {
  useRegisterDeviceMutation,
  useSubscribeToTopicMutation,
  useUnsubscribeFromTopicMutation
} from 'app/services/messaging';
import { getToken, logInCheck } from 'pages/auth/authSlice';
import TimeOutHandler from 'pages/auth/TimeOutHandler';

const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const baseUrl = process.env.REACT_APP_PUBLIC_URL || '/';
function ToastDisplay({ body, onDismiss }: { body: string; onDismiss: () => void }) {
  return (
    <Box
      display="flex"
      gap={1}
      flexGrow={1}
      maxWidth="100%"
      minWidth={300}
      height="54px"
      alignItems="center">
      <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="flex-start">
        <Typography fontSize="16px" fontWeight={400}>
          {body}
        </Typography>
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="flex-start">
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [showNotificationBanner, setShowNotificationBanner] = useState(
    Notification.permission === 'granted'
  );
  const isLoggedIn = useSelector(logInCheck);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  if (NODE_ENV === 'production') {
    import('./firebase').then(({ getFirebaseToken, onMessageListener }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [subscribeToTopic] = useSubscribeToTopicMutation();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [unsubscribeFromTopic] = useUnsubscribeFromTopicMutation();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [registerDevice] = useRegisterDeviceMutation();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const token = useSelector(getToken);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (Notification.permission === 'granted') {
          try {
            getFirebaseToken()
              .then((firebaseToken: any) => {
                if (firebaseToken) {
                  if (token) {
                    subscribeToTopic({ token: firebaseToken });
                    registerDevice(firebaseToken);
                    setShowNotificationBanner(true);
                  } else {
                    unsubscribeFromTopic({ token: firebaseToken });
                    setShowNotificationBanner(false);
                  }
                }
              })
              .catch((err: any) =>
                console.error('An error occured while retrieving firebase token. ', err)
              );
          } catch (err) {
            console.log(err);
          }
        } else {
          Notification.requestPermission()
            .then(() => {})
            .catch((e) => console.log(e));
        }

        return () => {};
      }, [subscribeToTopic, token]);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        onMessageListener()
          .then((payload) => {
            setNotification({
              title: payload?.notification?.title,
              body: payload?.notification?.body
            });
          })
          .catch((err) => console.log('failed: ', err));

        return () => {};
      });

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const notify = useCallback(() => {
        toast((t) => (
          <ToastDisplay body={notification.body} onDismiss={() => toast.dismiss(t.id)} />
        ));
      }, [notification.body]);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (notification?.title) {
          notify();
        }
      }, [notification, notify]);
    });
  }

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          // mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#b87e52' // Set your primary color here
          },
          secondary: {
            main: '#3d506f' // Set your secondary color here [#527eb8, #c4b852, #52b8b2, #528CB8, #3d506f]
            // main: '#007bff', // Set your secondary color here
          }
        }
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toast />
      <BrowserRouter basename={baseUrl}>
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 2 * 60 * 60 * 1000, // 2 houre then remove
            style: {
              backgroundColor: '#E3FCEF'
            }
          }}
        />
        <Routers />
      </BrowserRouter>
      {/* {!showNotificationBanner ? (
        <Box
          sx={{
            position: 'absolute',
            // bottom: 2,
            right: 0,
            marginLeft: 'auto',
          }}
        >
          <IconButton>
            <CircleNotificationsOutlinedIcon
              fontSize='large'
              color='error'
              sx={{ fontSize: '52px' }}
            />
          </IconButton>
        </Box>
      ) : null} */}
      {isLoggedIn && <TimeOutHandler />}
    </ThemeProvider>
  );
}

export default App;
