import List from '@mui/material/List';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

import menuListData from './menuList';
import { MenuItems } from './menuItems';
import { AbilityContext, Can } from 'common/casl/abilityContext';
import { useEffect, useState } from 'react';
import { SUBJECTS } from 'common/casl/ability';
import { useAbility } from '@casl/react';

type User = {
  roleCode: number;
};

const SidebarMenu = () => {
  const [menuList, setMenuList] = useState(menuListData);

  const user: User = JSON.parse(localStorage.getItem('user') ?? '{}');

  const ability = useAbility(AbilityContext);

  useEffect(() => {
    const agentJobDashboard =
      user.roleCode && (user.roleCode === 3003 || user.roleCode === 3002)
        ? {
            text: 'Agent Dashboard',
            link: 'jobs/agent',
            icon: () => <SupportAgentOutlinedIcon color="primary" />,
            type: '/jobs/agent',
            permission: SUBJECTS.AGENT_DASHBOARD
          }
        : undefined;

    const updatedMenuList = menuListData.map((menu) => {
      if (menu.text === 'Jobs' && menu?.items?.length && agentJobDashboard) {
        return {
          ...menu,
          items: [
            ...menu.items.filter((item) => item.text !== 'Agent Dashboard'),
            agentJobDashboard
          ].filter(Boolean)
        };
      }
      return menu;
    });

    setMenuList(updatedMenuList);
  }, [user.roleCode]);

  return (
    <List>
      {menuList
        .filter((menu) => {
          return (
            ability.can('READ', menu?.permission || '') ||
            (menu?.items?.length &&
              menu?.items?.length > 0 &&
              menu?.items?.filter(
                (item) => item?.permission && ability.can('READ', item?.permission || '')
              ).length > 0)
          );
        })
        .map((menu, index) => (
          <MenuItems listItems={menu} />
        ))}
    </List>
  );
};

export default SidebarMenu;
