import { CircularProgress, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, Control } from 'react-hook-form';

type InputFieldType = Partial<TextFieldProps> & {
  control: Control<any>;
  defaultValue?: unknown;
  loading?: boolean;
  children?: ReactNode;
};

const InputField = ({
  name = '',
  defaultValue = '',
  control,
  label,
  variant = 'outlined',
  size = 'medium',
  loading = false,
  children,
  ...rest
}: InputFieldType) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          autoComplete="off"
          fullWidth
          onChange={onChange}
          value={value || ''}
          id={name}
          label={label}
          variant={variant}
          size={size}
          {...rest}>
          {loading && rest?.select ? (
            <MenuItem disabled>
              <CircularProgress size={20} />
              <span style={{ marginLeft: 10 }}>Loading...</span>
            </MenuItem>
          ) : (
            children
          )}
        </TextField>
      )}
    />
  );
};

export default InputField;
